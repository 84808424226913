<template>
  <div class="workdetail" v-if="istrue">
    <div class="flex_column">
      <van-pull-refresh
        v-model="isLoading"
        @refresh="onRefresh"
        pulling-text="Loading..."
        loosing-text="Loading..."
        loading-text="Loading..."
      >
        <div class="container-water-fall case">
          <div class="bgwhite pb10">
            <div class="flex_between flex_items p10">
              <div class="flex_start flex_items">
                <van-image
                  v-if="info.headerUrl != null"
                  round
                  width="40"
                  height="40"
                  fit="cover"
                  :src="info.headerUrl"
                  class="mr10"
                ></van-image>
                <van-image
                  v-else
                  round
                  width="40"
                  height="40"
                  fit="cover"
                  :src="require('@/images/home/mtx.png')"
                  class="mr10"
                ></van-image>
                <div class="flex_column">
                  <p class="c0 f15 bold">{{ info.nickName }}</p>
                  <p class="f12" style="color: #8e8e8e">15 min ago</p>
                </div>
              </div>
              <div
                v-if="!info.follow"
                class="ptb5 plr10 f14"
                style="border-radius: 4px; background: #ff7900; color: white"
                @click="follow(info.author)"
              >
                Follow
              </div>
            </div>
            <!-- img -->
            <imgstype :imgs="imgs"></imgstype>
            <div class="flex_between flex_items p10">
              <div class="flex_start flex_items">
                <van-image
                  v-if="info.like"
                  fit="contain"
                  :src="require('@/images/home/zan1.png')"
                  class="mr15"
                  width="24"
                ></van-image>
                <van-image
                  v-else
                  fit="contain"
                  :src="require('@/images/home/zan.png')"
                  class="mr15"
                  width="24"
                  @click.stop="zan(info.author, info.permlink)"
                ></van-image>
                <van-image
                  fit="contain"
                  :src="require('@/images/home/pinlun.png')"
                  class="mr15"
                  width="24"
                ></van-image>
                <div @click.stop="zhuan()" class="mr15" style="height: 24px">
                  <zhuanshow
                    :isshow="zhuanshow"
                    :author="info.author"
                    :permlink="info.permlink"
                  ></zhuanshow>
                </div>
                <div style="height: 24px" @click.stop="moreshowclisk">
                  <van-image
                    fit="contain"
                    :src="require('@/images/home/more.png')"
                    class="mr15"
                    width="24"
                  ></van-image>
                </div>
              </div>
              <div class="rpix bold f12 plr10">
                {{ info.totalMoney }}&nbsp;PIX
              </div>
            </div>
            <!-- likes -->
            <div class="flex_start flex_items plr10">
              <span class="mr5 f13"
                ><span class="bold">{{ info.likeNum }}&nbsp;</span
                ><span v-if="info.likeNum < 2">Like</span>
                <span v-else>Likes</span>
              </span>
              <span class="mr5 f13 bold">·</span>
              <span class="mr5 f13"
                ><span class="bold">{{ info.commentNum }}&nbsp;</span
                ><span v-if="info.commentNum < 2">Comment</span
                ><span v-else>Comments</span></span
              >
            </div>
            <p
              v-if="info.description != null"
              class="f14 c0 mt10 plr10"
              @click.stop="workdetail(info.author, info.permlink)"
            >
              {{ info.description }}
            </p>
            <div
              v-if="info.primaryTag != null"
              class="flex_warp flex_items f14 mt5"
              style="color: #0a82fa"
            >
              <span class="ml10 mt5">#{{ info.primaryTag }}</span>
              <span class="ml10 mt5" v-for="item in info.tags" :key="item.id"
                >#{{ item }}</span
              >
            </div>
            <div
              v-if="info.hotState == 1 || info.nftStatus == 1"
              class="mt10 flex_start flex_items plr10"
            >
              <div
                v-if="info.hotState == 1"
                class="fire plr5 flex_start flex_items mr10"
              >
                <van-image
                  fit="contain"
                  :src="require('@/images/home/fire.png')"
                  class="mr5"
                  width="14"
                ></van-image>
                <span class="f12 c0">Trending</span>
              </div>
              <div
                v-if="info.nftStatus == 2"
                class="fire plr5 flex_center flex_items mr10"
              >
                <van-image
                  fit="contain"
                  :src="require('@/images/home/nft2.png')"
                  class="mr5"
                  width="20"
                ></van-image>
              </div>
            </div>
          </div>
          <!-- comments -->
          <div style="width: 100%; height: 10px; background: #f5f5f5"></div>
          <div class="bgwhite">
            <p class="p10 f13 bbe9s bold">
              {{ info.repliesNum }}
              <span v-if="info.repliesNum < 2">Comment</span
              ><span v-else>Comments</span>
            </p>
            <div class="plr10 pb30" v-if="replies.length != 0">
              <div
                class="flex_start mt15"
                v-for="(item, index) in replies"
                :key="index"
              >
                <van-image
                  fit="contain"
                  :src="item.headerUrl"
                  class="mr10"
                  width="30"
                  height="30"
                  round
                ></van-image>
                <div class="flex_column" style="width: 83vw">
                  <div class="flex_between flex_items">
                    <div class="flex_column">
                      <span class="f12 c0 bold">{{ item.nickName }}</span>
                      <span class="mt5 f13 c0">{{ item.body }}</span>
                    </div>
                    <van-image
                      fit="contain"
                      width="14"
                      class="mr10"
                      :src="require('@/images/home/xiaoxin1.png')"
                    ></van-image>
                  </div>
                  <div class="flex_between flex_items mt10">
                    <div
                      class="flex_start flex_items f12"
                      style="color: #666666"
                    >
                      <span class="mr10"
                        >{{ item.likeNum }}&nbsp;<span v-if="item.likeNum < 2"
                          >Like</span
                        ><span v-else>Likes</span></span
                      >
                      <span>Reply</span>
                    </div>
                    <span class="f12 mr10" style="color: #666666"
                      >yesterday 15:27</span
                    >
                  </div>
                  <!-- reply -->
                  <div
                    class="flex_column plr10 pb10 mt15"
                    style="background: #f5f5f5"
                    v-if="item.replies != null"
                  >
                    <div
                      class="flex_start mt10"
                      v-for="(item2, index2) in item.replies"
                      :key="index2"
                    >
                      <van-image
                        fit="contain"
                        :src="info.headerUrl"
                        class="mr10"
                        width="30"
                        height="30"
                        round
                      ></van-image>
                      <div class="flex_column" style="width: 67vw">
                        <div class="flex_between flex_items">
                          <div class="flex_column">
                            <span class="f12 c0 bold">{{
                              item2.nickName
                            }}</span>
                            <span class="mt5 f13 c0">{{ item2.body }}</span>
                          </div>
                          <van-image
                            fit="contain"
                            width="14"
                            :src="require('@/images/home/xiaoxin.png')"
                            v-if="item2.like"
                          ></van-image>
                          <van-image
                            v-else
                            fit="contain"
                            width="14"
                            :src="require('@/images/home/xiaoxin1.png')"
                          ></van-image>
                        </div>
                        <div class="flex_between flex_items mt10">
                          <div
                            class="flex_start flex_items f12"
                            style="color: #666666"
                          >
                            <span class="mr10"
                              >{{ item2.likeNum }}&nbsp;<span
                                v-if="item2.likeNum < 2"
                                >Like</span
                              ><span v-else>Likes</span></span
                            >
                            <span>Reply</span>
                          </div>
                          <span class="f12" style="color: #666666"
                            >yesterday 15:27</span
                          >
                        </div>
                      </div>
                    </div>
                    <p
                      style="color: #0a82fa"
                      class="f12 mt10 pl40"
                      @click="allreply(item)"
                    >
                      View reply details
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              style="height: 60px; color: #8d8d8d"
              class="f-center ptb20 f14"
            >
              No comments
            </div>
          </div>
        </div>
      </van-pull-refresh>
    </div>
    <!-- more -->
    <van-popup
      v-model="ismoreshow"
      class="p10"
      style="border-radius: 4px; width: 60vw"
    >
      <div
        style="width: 100%"
        class="ptb10 f14 c0"
        @click="follow(info.author)"
      >
        {{ isfollw }}
      </div>
      <div style="width: 100%" class="ptb10 f14 c0" @click="collection">
        {{ iscollection }}
      </div>
      <div style="width: 100%" class="ptb10 f14 c0" @click="report">
        Report this Post
      </div>
    </van-popup>
    <!-- isreport -->
    <van-popup
      v-model="isreport"
      class="p10"
      style="border-radius: 4px; width: 60vw"
    >
      <div
        style="width: 100%"
        class="ptb10 f14 c0"
        v-for="item in resons"
        :key="item.id"
        @click="reportsub(item.id)"
      >
        {{ item.title }}
      </div>
    </van-popup>
    <div class="down" v-if="$route.path != '/workdetails'?isDown: !isDown">
      <div class="close" @click="isDown = !isDown"><img src="../../images/home/close.png" alt=""></div>
      <p>This post has already earned</p>
      <p class="pix">{{ info.totalMoney }} PIX (Crypto)</p>
      <p>Download Pixie and like this post to</p>
      <p class="last">earn crypto together with me.</p>                          
      <button @click="downloads">Download</button>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import imgstype from "@/components/imgstype.vue";
import zhuanshow from "@/components/zhuanshow.vue";
export default {
  data() {
    return {
      istrue: false,
      info: {},
      imgs: {},
      replies: [],
      isLoading: false,
      ismoreshow: false,
      zhuanshow: false,
      isreport: false,
      isfollw: "Follow",
      iscollection: "Save to Collection",
      resons: [
        { id: 0, title: "Spam" },
        { id: 1, title: "Pornographic" },
        { id: 2, title: "Copyright Infringement" },
        { id: 3, title: "Other Reasons" },
      ],
      isDown: true,
    };
  },
  components: {
    imgstype,
    zhuanshow,
  },
  // activated() {
  //   if (!this.$route.meta.isback) {
  //     this.getData(this.$route.params.author, this.$route.params.permlink);
  //   }
  // },
  mounted() {
    this.getData(this.$route.params.author, this.$route.params.permlink);
  },
  methods: {
    getData(author, permlink) {
      if (!this.isLoading) {
        Toast.loading({
          message: "Loading...",
          forbidClick: true,
        });
      }
      this.util
        .get(this.HOST + "/posts/" + author + "/" + permlink, {
          appVersion: "1.0.3",
          author: author,
          country: "cn",
          deviceName: "iPhone10",
          language: "en",
          myAccount: localStorage.getItem("account"),
          osVersion: "14.6",
          permlink: permlink,
          platform: "2",
          timestamp: Date.parse(new Date()),
          timezone: "Asia/Shanghai",
          version: "2",
        })
        .then((res) => {
          console.log(res);
          if (res.code == "000") {
            this.imgs = res.data.images;
            this.info = res.data;
            this.getreply();
          } else {
            Toast(res.msg);
          }
        });
    },
    getreply() {
      this.util
        .get(this.HOST + "/posts/comment-replies", {
          // appVersion: "1.0.5",
          country: "ph",
          // deviceName: "iPhone10",
          language: "en",
          limit: 20,
          parent: this.info.author,
          // osVersion: "14.6",
          parent_permlink: this.info.permlink,
          platform: "2",
          start_author: "",
          start_permlink: "",
          timestamp: Date.parse(new Date()),
          timezone: "Asia/Manila",
        })
        .then((res) => {
          console.log(res);
          if (res.code == "000") {
            this.replies = res.data.list;
          } else {
            Toast(res.msg);
          }
          this.isLoading = false;
          this.istrue = true;
        });
    },
    allreply(item) {
      this.downloads()
      // let arr = [];
      // arr.push({
      //   headerUrl: item.headerUrl,
      //   body: item.body,
      //   nickName: item.nickName,
      //   likeNum: item.likeNum,
      //   createdTime: item.createdTime,
      //   like: item.like,
      // });
      // item.replies.forEach((item2) => {
      //   arr.push({
      //     headerUrl: item2.headerUrl,
      //     body: item2.body,
      //     nickName: item2.nickName,
      //     likeNum: item2.likeNum,
      //     createdTime: item2.createdTime,
      //     like: item2.like,
      //     zt: true,
      //   });
      // });
      // this.$router.push({
      //   name: "allreply",
      //   params: {
      //     replies: JSON.stringify(arr),
      //   },
      // });
    },
    zan(author, permlink) {
      this.downloads()
      // this.util
      //   .zan(this.HOST + "/likes", {
      //     author: author,
      //     permlink: permlink,
      //     appVersion: "1.0.5",
      //     country: "cn",
      //     deviceName: "iPhone12",
      //     language: "en",
      //     platform: "2",
      //     timestamp: Date.parse(new Date()),
      //     timezone: "Asia/Shanghai",
      //     version: "2",
      //     voter: localStorage.getItem("account"),
      //   })
      //   .then((res) => {
      //     this.info.like = true;
      //     this.info.likeNum += 1;
      //   });
    },
    zhuan() {
      this.downloads()
      // this.zhuanshow = !this.zhuanshow;
    },
    moreshowclisk() {
      this.downloads()
      // if (this.info.follow) {
      //   this.isfollw = "Unfollow";
      // } else {
      //   this.isfollw = "Follow";
      // }
      // if (this.info.collection) {
      //   this.iscollection = "Remove from Collection";
      // } else {
      //   this.iscollection = "Save to Collection";
      // }
      // this.ismoreshow = true;
    },
    follow(author) {
      this.downloads()
      // Toast.loading({
      //   message: "Loading...",
      //   forbidClick: true,
      // });
      // if (this.isfollw == "Unfollow") {
      //   this.unfollow(author);
      //   return false;
      // }
      // this.util
      //   .follow(this.HOST + "/follow", {
      //     following: author,
      //     appVersion: "1.0.5",
      //     country: "cn",
      //     deviceName: "iPhone12",
      //     language: "en",
      //     osVersion: "14.7.1",
      //     platform: "2",
      //     timestamp: Date.parse(new Date()),
      //     timezone: "Asia/Shanghai",
      //   })
      //   .then((res) => {
      //     if (res.code == "000") {
      //       Toast("Following");
      //       this.isfollw = "Unfollow";
      //       this.info.follow = true;
      //     } else {
      //       Toast(res.msg);
      //     }
      //     this.ismoreshow = false;
      //   });
    },
    unfollow(author) {
      this.downloads()
      // this.util
      //   .follow(this.HOST + "/unfollow", {
      //     following: author,
      //     appVersion: "1.0.5",
      //     country: "cn",
      //     deviceName: "iPhone12",
      //     language: "en",
      //     osVersion: "14.7.1",
      //     platform: "2",
      //     timestamp: Date.parse(new Date()),
      //     timezone: "Asia/Shanghai",
      //   })
      //   .then((res) => {
      //     if (res.code == "000") {
      //       Toast("Unfollowed");
      //       this.isfollw = "Follow";
      //       this.info.follow = false;
      //     } else {
      //       Toast(res.msg);
      //     }
      //     this.ismoreshow = false;
      //   });
    },
    collection() {
      this.downloads()
      // Toast.loading({
      //   message: "Loading...",
      //   forbidClick: true,
      // });
      // let types = 1;
      // let mes = "Canceled";
      // if (this.iscollection == "Save to Collection") {
      //   types = 0;
      //   mes = "Saved to Collection";
      // }
      // this.util
      //   .follow(this.HOST + "/collection", {
      //     type: types,
      //     permlink: this.info.permlink,
      //     author: this.info.author,
      //   })
      //   .then((res) => {
      //     if (res.code == "000") {
      //       Toast(mes);
      //       if (types == 0) {
      //         this.info.collection = true;
      //       } else {
      //         this.info.collection = false;
      //       }
      //     } else {
      //       Toast(res.msg);
      //     }
      //     this.ismoreshow = false;
      //   });
    },
    report() {
      this.downloads()
      // this.ismoreshow = false;
      // this.isreport = true;
    },
    reportsub(id) {
      this.downloads()
      // Toast.loading({
      //   message: "Loading...",
      //   forbidClick: true,
      // });
      // this.util
      //   .report(this.HOST + "/reporting", {
      //     permlink: this.info.permlink,
      //     author: this.info.author,
      //     type: id,
      //     account: localStorage.getItem("account"),
      //     imageKey: this.info.body,
      //   })
      //   .then((res) => {
      //     if (res.code == "000") {
      //       Toast("Thank you for your feedback");
      //     } else {
      //       Toast(res.msg);
      //     }
      //     this.ismoreshow = false;
      //     this.isreport = false;
      //   });
    },
    onRefresh() {
      this.downloads()
      // this.getData(this.$route.params.author, this.$route.params.permlink);
    },
    downloads() {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //
      // window.location = "https://durablelinks.pixie.xyz/durablelinks/bwfEU8FH4N7VEyXg8"

      var author = this.$route.params.author;
      var permlink = this.$route.params.permlink;

      var now = new Date().valueOf();
      setTimeout(function () {
        // if (new Date().valueOf() - now > 100) return;
        window.location = "https://durablelinks.pixie.xyz/durablelinks/3utYvrF79BP8hBLh9";
      }, 3000);

      try {
        location.href = "pixie://?pixbe=photoComment.main&account=" + author + "&photoId=" + permlink;
      } catch (e) {
        
      }

      //
      // if (isAndroid == true) {
      //   window.open(
      //     "https://play.google.com/store/apps/details?id=mobi.pixie.app"
      //   );
      // } else {
      //   window.open("https://apps.apple.com/us/app/pixie-make-social-different/id1585943863");
      //   // this.jumpToBottom();
      // }
    },
  },
  beforeCreate(to, from, next) {
    window.document.body.style.backgroundColor = "#fff";
  },
  beforeDestroy(to, from, next) {
    window.document.body.style.backgroundColor = "";
  },
};
</script>
<style>
.workdetail .rpix {
  background: linear-gradient(85deg, #ffead6 0%, #ffe0c2 100%);
  opacity: 1;
  border-radius: 4px;
  color: #ff5500;
  height: 20px;
  line-height: 20px;
}
.workdetail .fire {
  background: #f5f5f5;
  border-radius: 6px 0px 6px 0px;
  height: 26px;
  line-height: 26px;
}
.down {
  width: calc(100% - 40px);
  min-height: 158px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  text-align: center;
  background: rgb(000, 000, 000, 0.6);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 18px 20px 23px;
  line-height: 20px;
  color: #fff;
  /* font-family: SFProText-Semibold; */
}
.down .pix {
  color: #FFFF00;
  font-size: 17px;
  margin-bottom: 10px;
}

.down button {
  width: 100%;
  height: 48px;
  color: #fff;
  font-size: 15px;
  /*font-family: SF Pro Text;*/
  border: none;
  /* border: 4px solid rgba(255, 255,255,0.85); */
  border-radius: 6px;
  background:linear-gradient(#FF7900,#FF5500);
  /* margin-top: 20px; */
  /* box-shadow: 0px 0px 18px #f3c1a8; */
  display: block;
  margin-top: 20px;
}
.down .close {
  position: absolute;
  top: 10px;
  right: 23px;
}
.down .close img {
  width: 22px;
  height: 22px;
}
</style>